import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { KILOWATT_HOUR } from '../../../utils/consts'
import { addCommasToNumber } from '../../../utils/helpers'
import { useGetAnalysisConsumptionBreakdownQuery } from '../api/analysisApi'
import { SelectableItem } from '../api/types'

interface Props {
  selectedHours: SelectableItem[]
  onClose: () => void
  isOpen: boolean
}

const DevicesBreakdown = ({ selectedHours, onClose, isOpen }: Props) => {
  const [searchParams] = useSearchParams()
  const locationId = parseInt(searchParams.get('location')!)

  const requestData = useMemo(() => {
    const periods = selectedHours.reduce<{ [index: string]: string[][] }>(
      (acc, { day, hour }) => {
        let shouldAddNew = true
        let currentDay = acc[day]
        if (!currentDay) {
          currentDay = []
          acc[day] = currentDay
        }

        const startHour = hour
        const endHour = hour
        const startHourStr = `${hour}:00`
        const endHourStr = `${hour}:00`
        if (currentDay.length) {
          currentDay.map((hours) => {
            const currentDayStartHour = parseInt(hours[0].split(':')[0])
            const currentDayEndHour = parseInt(hours[1].split(':')[0])
            if (currentDayStartHour === startHour - 1) {
              if (currentDayEndHour < endHour) {
                hours[1] = `${endHour}:00`
                shouldAddNew = false
              }
            }
            if (currentDayEndHour === startHour - 1) {
              hours[1] = `${endHour}:00`
              shouldAddNew = false
            }

            return hours
          })

          if (shouldAddNew) {
            currentDay.push([startHourStr, endHourStr])
          }
          return acc
        }
        currentDay.push([startHourStr, endHourStr])
        return acc
      },
      {},
    )

    const totalConsumption = selectedHours.reduce(
      (sum, { consumption }) => sum + consumption,
      0,
    )

    return {
      location_id: locationId,
      period: periods,
      total_consumption: totalConsumption,
    }
  }, [selectedHours, locationId])

  const { isLoading, currentData = {} } =
    useGetAnalysisConsumptionBreakdownQuery(requestData)

  console.log(currentData)

  const unmeasuredEnergy = useMemo(() => {
    const totalDeviceConsumption = Object.values(currentData).reduce(
      (sum, value) => sum + value,
      0,
    )

    const unmeasured = Math.max(0, requestData.total_consumption - totalDeviceConsumption)
    return unmeasured
  }, [currentData, requestData])

  return (
    <>
      {!isLoading ? (
        <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent minWidth="fit-content">
            <ModalHeader>Devices Breakdown</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TableContainer>
                <Table variant="striped" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th>Device name</Th>
                      <Th>Total Consumption</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.keys(currentData).map((key) => (
                      <Tr key={key}>
                        <Td>{key}</Td>
                        <Td>
                          {addCommasToNumber(currentData[key])} {KILOWATT_HOUR}
                        </Td>
                      </Tr>
                    ))}
                    {unmeasuredEnergy > 0 && (
                      <Tr>
                        <Td>Unmeasured Energy</Td>
                        <Td>
                          {addCommasToNumber(unmeasuredEnergy)} {KILOWATT_HOUR}
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}

export default DevicesBreakdown
