export const CURRENT_USAGE_POLLING_INTERVAL = 60000
export const BOOKMARK_TEMPLATE_LIMIT = 20
export const RECURRING_TEMPLATE_LIMIT = 10
export const BOOKMARK_TOOLTIP = "Bookmark"
export const UNBOOKMARK_TOOLTIP = "Unbookmark"

export const KILOWATT_HOUR = 'kWh'

export const KILOWATT = 'kW'
export const CELSIUM = '°C'
export const HUMIDITY = '%'
export const AMPERS = 'A'
export const TVOC = 'ppb'
export const CO2 = 'ppm'
export const CARBON_INTENSITY = 'g'
export const MONETARY_VALUE = 'BGN'
export const CARBON_FOOTPRINT_KG = 'kg'
export const ENERGY_GENERATOR = 'energy_generator'
export const VOLTAGE = 'V'
export const ENERGY_GENERATOR_KEY = 'generated_power'
export const ENERGY_PLANT_LOGGER = 'energy_plant_logger'
export const CLIMATE_CONTROL = 'climate_control'
export const AIRQUALITY = 'air_quality'

export const GENERATORS_HEADERS = ['Device name', 'Generated energy']

export const MEASUREMENT_UNITS_MAP = {
  'Carbon Intensity': CARBON_INTENSITY,
  'Total Consumption': KILOWATT_HOUR,
  'Max Voltage': VOLTAGE,
  'Min Voltage': VOLTAGE,
  'Produced Energy': KILOWATT_HOUR,
  'Total Production': KILOWATT_HOUR,
  Current: AMPERS,
}

export const GENERATOR_KEYS = [ENERGY_GENERATOR_KEY]

export enum ReportType {
  Pdf = 'PDF',
  Csv = 'Csv',
}