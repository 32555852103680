import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Flex,
  Stack,
  Text,
  useMediaQuery,
  Grid,
  GridItem,
  Skeleton,
  Link,
} from '@chakra-ui/react'
import Header from '../../../app/components/Header'
import { LocationTabStrip } from '../components/LocationTabStrip'
import { useQueryParams } from '../hooks/useQueryParams'
import { DEFAULTPERIOD, PERIODPARAMS } from '../consts'
import {
  useGetConsumptionPriceByLocationIdQuery,
  useGetCurrentLocationsDataQuery,
  useGetDashboardLocationQuery,
} from '../api/dasboardApi'
import { PeriodTabStrip } from '../components/PeriodTabStrip'
import { ConsumedEnergyIcon } from '../../../assets/icons/ConsumedEnergy'
import { addCommasToNumber } from '../../../utils/helpers'
import {
  AIRQUALITY,
  CELSIUM,
  CLIMATE_CONTROL,
  CURRENT_USAGE_POLLING_INTERVAL,
  ENERGY_GENERATOR,
  ENERGY_PLANT_LOGGER,
  HUMIDITY,
  KILOWATT,
  KILOWATT_HOUR,
  MONETARY_VALUE,
} from '../../../utils/consts'
import { RechartsAreaChart } from '../../../app/components/AreaChart'
import DevicesContainer from '../components/DevicesContainer'
import useRedirectOn404 from '../../../app/hooks/useRedirectTo404'
import Flippable from '../components/Flippable'
import ProducedEnergyIcon from '../../../assets/icons/ProducedEnergyIcon'
import CostForConsumptionIcon from '../../../assets/icons/CostConsumptionIcon'
import GeneratedIncomeIcon from '../../../assets/icons/GeneretedIncomeIcon'
import { AverageLineChart } from '../../../app/components/AverageLineChart'
import TempDownIcon from '../../../assets/icons/TempDownIcon'
import HumidityDownIcon from '../../../assets/icons/HumidityDownIcon'

const DashboardLocation: React.FC<{
  locations: any
  overviewFetching: boolean
}> = ({ locations, overviewFetching }) => {
  const { locationId } = useParams()
  const [queryParams] = useQueryParams(PERIODPARAMS)

  const locationFlag = locationId
  const dashboardLocationData = useGetDashboardLocationQuery(
    { ...DEFAULTPERIOD, ...queryParams, location: Number(locationId) },
    { skip: !locationFlag },
  )
  const { data: energyPrice, isFetching: isEnergyPriceLoading } =
    useGetConsumptionPriceByLocationIdQuery({
      ...DEFAULTPERIOD,
      ...queryParams,
      location: locationId,
    })

  const {
    currentData: location,
    isFetching: isLoading,
    error: getLocationError,
  } = dashboardLocationData
  useRedirectOn404(getLocationError, 'Location not found')

  const { data: currentLocationsData = [] } = useGetCurrentLocationsDataQuery(
    {
      locationIds: [Number(locationId)],
    },
    {
      pollingInterval: CURRENT_USAGE_POLLING_INTERVAL,
    },
  )

  // TODO: Temporary checks
  const mainConsumptionDevice = location?.devices.find(
    (x) =>
      x.is_main &&
      x.category !== ENERGY_GENERATOR &&
      x.category !== ENERGY_PLANT_LOGGER &&
      x.category !== AIRQUALITY &&
      x.category !== CLIMATE_CONTROL,
  )

  const generators = useMemo(() => {
    return location?.devices.filter(
      (device) =>
        device.category === ENERGY_GENERATOR || device.category === ENERGY_PLANT_LOGGER,
    )[0]
  }, [location])

  const chartDataKeys = []

  if (mainConsumptionDevice) {
    chartDataKeys.push({
      key: 'avg_consumption',
      displayValue: 'Total Consumption',
    })
  }
  if (generators) {
    chartDataKeys.push({
      key: 'avg_generated_power',
      displayValue: 'Total Production',
    })
  }

  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)')
  const HeaderContent = <Header title="Overview" />

  const dynamicTitle = useCallback(() => {
    const titleParts = [
      mainConsumptionDevice ? 'Total Consumption' : '',
      generators ? 'Total Production' : '',
    ].filter(Boolean)

    return titleParts.join(' and ')
  }, [mainConsumptionDevice, generators])

  return (
    <>
      {!isSmallerThan1200 && HeaderContent}

      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}>
        <GridItem>
          <LocationTabStrip
            data={locations}
            clearNavigationPath="dashboard"
            handleNavigationPath="dashboard/location"
          />
        </GridItem>
        <GridItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box py="15px">
            <PeriodTabStrip />
          </Box>
        </GridItem>
      </Grid>

      <Stack w="full" spacing={5}>
        {locationFlag ? (
          <>
            <Flex
              flexGrow={1}
              p="4"
              rounded="lg"
              alignItems="flex-start"
              flexDirection="column"
              bgColor="white"
              w={{ base: '100%', lg: 'auto' }}
            >
              <Grid
                templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                gap={4}
                w="full"
                py="16px"
              >
                <GridItem>
                  <Skeleton isLoaded={!isLoading}>
                    <Box rounded="lg" bg="container.background.gray" px="20px" py="16px">
                      <Flex align="center" gap="16px">
                        <ConsumedEnergyIcon boxSize="32px" color="text.secondary" />
                        <Flex direction="column">
                          <Text color="text.secondary">Total consumed energy</Text>

                          <Flex gap="2" align="center">
                            <Text color="text.dark" fontSize="20px" fontWeight="700">
                              {addCommasToNumber(
                                location?.energy_data.total_consumption || 0,
                              )}
                            </Text>
                            <Text>{KILOWATT_HOUR}</Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                  </Skeleton>
                </GridItem>
                <GridItem>
                  <Skeleton isLoaded={!isLoading}>
                    <Box rounded="lg" bg="container.background.gray" px="20px" py="16px">
                      <Flex align="center" gap="16px">
                        <ConsumedEnergyIcon boxSize="32px" color="text.secondary" />
                        <Flex direction="column">
                          <Text color="text.secondary">Current Usage</Text>

                          <Flex gap="2" align="center">
                            <Flippable
                              value={
                                <Text color="text.dark" fontSize="20px" fontWeight="700">
                                  {addCommasToNumber(
                                    currentLocationsData[locationId]?.consumption || 0,
                                  )}
                                </Text>
                              }
                              unit={KILOWATT}
                              hovered
                              hoveredBgColor="container.background.gray"
                              topMargin={2}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                  </Skeleton>
                </GridItem>
                <GridItem>
                  <Skeleton isLoaded={!isLoading}>
                    <Box rounded="lg" bg="container.background.gray" px="20px" py="16px">
                      <Flex align="center" gap="16px">
                        <ProducedEnergyIcon boxSize="32px" color="text.secondary" />
                        <Flex direction="column">
                          <Text color="text.secondary">Produced energy</Text>
                          <Flex gap="2" align="center">
                            <Text color="text.dark" fontSize="20px" fontWeight="700">
                              {addCommasToNumber(
                                location?.energy_data.total_generated_power || 0,
                              )}
                            </Text>
                            <Text>{KILOWATT_HOUR}</Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                  </Skeleton>
                </GridItem>
                <GridItem colSpan={3}>
                  <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
                    <GridItem>
                      <Skeleton isLoaded={!isEnergyPriceLoading}>
                        <Box
                          rounded="lg"
                          bg="container.background.gray"
                          px="20px"
                          py="16px"
                        >
                          <Flex align="center" gap="16px">
                            <CostForConsumptionIcon
                              boxSize="32px"
                              color="text.secondary"
                            />
                            <Flex direction="column">
                              <Text color="text.secondary">Cost for consumed energy</Text>
                              <Flex gap="2" align="center">
                                <Text color="text.dark" fontSize="20px" fontWeight="700">
                                  {addCommasToNumber(energyPrice?.total_cost || 0)}
                                </Text>
                                <Text>{MONETARY_VALUE}</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                      </Skeleton>
                    </GridItem>
                    <GridItem>
                      <Skeleton isLoaded={!isEnergyPriceLoading}>
                        <Box
                          rounded="lg"
                          bg="container.background.gray"
                          px="20px"
                          py="16px"
                        >
                          <Flex align="center" gap="16px">
                            <GeneratedIncomeIcon boxSize="32px" color="text.secondary" />
                            <Flex direction="column">
                              <Text color="text.secondary">
                                Generated income through renewable
                              </Text>
                              <Flex gap="2" align="center">
                                <Text color="text.dark" fontSize="20px" fontWeight="700">
                                  {addCommasToNumber(
                                    energyPrice?.total_generated_income || 0,
                                  )}
                                </Text>
                                <Text>{MONETARY_VALUE}</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                      </Skeleton>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem colSpan={3}>
                  <Skeleton isLoaded={!isLoading}>
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
                      {location?.has_temp_sensor &&
                      location?.temp_and_humidity.length > 0 ? (
                        <>
                          <GridItem>
                            <AverageLineChart
                              data={location?.temp_and_humidity}
                              period={queryParams['period'] || 'day'}
                              lineProps={{
                                dataKey: 'avg_temperature',
                                type: 'ss',
                              }}
                              title={'Temperature'}
                              measurementUnit={CELSIUM}
                              icon={<TempDownIcon />}
                            />
                          </GridItem>
                          <GridItem>
                            <AverageLineChart
                              data={location?.temp_and_humidity}
                              period={queryParams['period'] || 'day'}
                              lineProps={{
                                dataKey: 'avg_humidity',
                                type: 'ss',
                              }}
                              title={'Humidity'}
                              measurementUnit={HUMIDITY}
                              icon={<HumidityDownIcon />}
                            />
                          </GridItem>
                        </>
                      ) : null}
                    </Grid>
                  </Skeleton>
                </GridItem>
              </Grid>
            </Flex>

            <Grid
              templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
              gap={4}
              w="full"
            >
              <GridItem>
                <Flex
                  direction="column"
                  gap={generators && mainConsumptionDevice ? '4' : 0}
                >
                  <Box bg="white" rounded="lg">
                    <Skeleton isLoaded={!isLoading} height={isLoading ? '369px' : 'auto'}>
                      {generators && (
                        <DevicesContainer
                          devices={generators}
                          totalConsumption={location?.energy_data.total_generated_power}
                          title="Generators"
                          type="generator"
                          locationId={locationId}
                        />
                      )}
                    </Skeleton>
                  </Box>

                  <Box bg="white" rounded="lg">
                    <Skeleton isLoaded={!isLoading} height={isLoading ? '369px' : 'auto'}>
                      {mainConsumptionDevice && (
                        <DevicesContainer
                          title="Consumers"
                          devices={mainConsumptionDevice}
                          totalConsumption={location?.energy_data.total_consumption}
                          type="consumers"
                          locationId={locationId}
                        />
                      )}
                    </Skeleton>
                  </Box>
                </Flex>
              </GridItem>
              <GridItem>
                <Skeleton isLoaded={!isLoading} height={isLoading ? '369px' : 'auto'}>
                  {location?.main_device_average_energy?.length > 0 ? (
                    <Box bg="white" rounded="lg" px="20px" py="16px">
                      <>
                        {mainConsumptionDevice?.id ? (
                          <Link
                            href={`/deviceInfo?location=${locationId}&device=${mainConsumptionDevice?.id}`}
                          >
                            <Text
                              pl="10px"
                              pb="16px"
                              fontSize="14px"
                              fontWeight="700"
                              color="text.secondary"
                            >
                              {mainConsumptionDevice.title}
                            </Text>
                          </Link>
                        ) : (
                          dynamicTitle()
                        )}

                        <RechartsAreaChart
                          data={location?.main_device_average_energy}
                          period={queryParams['period'] || 'day'}
                          dataKeys={chartDataKeys}
                        />
                      </>
                    </Box>
                  ) : null}
                </Skeleton>
              </GridItem>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Stack>
    </>
  )
}

export default DashboardLocation
